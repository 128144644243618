<template>
  <div class="home-wrapper--search-autocomplete">
    <el-container class="wrapper-search-autocomplete">
      <el-main class="d--grid">
        <el-row :gutter="20" class="container-row">
          <el-col v-loading="loading" :span="24">
            <div class="wapper-search-left">
              <LazyLoading
                :key="`search`"
                :loaded="lazy.search"
                @loaded="(e) => (lazy.search = e)"
              >
                <LadAutocompleteSearch
                  v-if="lazy.search"
                  :key="`home-search`"
                  :name="`home-search`"
                  :advanced="true"
                  :submit="submit"
                  @is-valid="handleIsValid"
                  @on-loading="handleLoading"
                />
              </LazyLoading>
            </div>
          </el-col>

          <el-col :span="24">
            <div class="search-wrapper-submit">
              <el-button-group class="container-switch">
                <el-button
                  type="success"
                  icon="el-icon-search"
                  round
                  class="button--search"
                  :disabled="disabled"
                  @click="submit++"
                  >{{ $t(`general.search`) }}</el-button
                >
                <el-button
                  type="default"
                  round
                  class="button--find-my-tour"
                  @click="handleOpenTour"
                  >{{ $t(`${base}.find_my_tour.title`) }}</el-button
                >
              </el-button-group>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <LadLocalFlexSummary />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeSearch',
  components: {
    LadAutocompleteSearch: () =>
      import(
        /* webpackChunkName: "lad-autocomplete-search" */ '@/components/search/index'
      ),
    LadLocalFlexSummary: hydrateWhenVisible(() =>
      import(
        /* webpackChunkName: "lad-local-flex-summary" */ '@/components/local-flex-summary'
      )
    ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'home.search',
      lazy: {
        search: false,
      },
      submit: 0,
      loading: false,
      disabled: true,
      showFindMyTour: false,
    }
  },
  computed: {
    ...mapState('modals', {
      isFindMyTourOpen: (state) => state.findMyTour.opened,
    }),
    hotelLink() {
      return process.env.APP_URL.replace(
        'localadventures',
        'hotels.localadventures'
      )
    },
  },
  methods: {
    async handleOpenTour() {
      await this.$store.dispatch('modals/toggle', 'findMyTour')

      if (typeof this.$gtag !== 'undefined') {
        this.$gtag('event', 'find_my_trip')
      }
    },
    handleIsValid(value) {
      this.disabled = !value
    },
    handleLoading(value) {
      this.loading = value
      this.disabled = value
    },
  },
}
</script>

<style lang="scss">
.home-wrapper--search-autocomplete {
  position: relative;
  min-height: 250px;
  z-index: 5;
  background: rgb(48, 134, 66);
  background: -moz-linear-gradient(
    0deg,
    rgba(48, 134, 66, 1) 0%,
    rgba(37, 160, 72, 1) 65%,
    rgba(67, 190, 93, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(48, 134, 66, 1) 0%,
    rgba(37, 160, 72, 1) 65%,
    rgba(67, 190, 93, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(48, 134, 66, 1) 0%,
    rgba(37, 160, 72, 1) 65%,
    rgba(67, 190, 93, 1) 100%
  );
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#308642",endColorstr="#43be5d",GradientType=1);

  .wrapper-search-autocomplete {
    min-height: 105px;

    .container-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (min-width: 767.98px) {
        justify-content: start;
      }
    }

    .wrapper-search-type {
      position: relative;
      z-index: 10;
      margin-top: -1px;

      .el-tabs {
        margin-left: 20px;

        &__content {
          display: none;
        }

        &--card {
          & > .el-tabs__header {
            margin-top: 0;
            border-color: transparent;

            .el-tabs__nav {
              border-radius: 0;
              border-top: 0;
              border-color: transparent;
            }

            .el-tabs__item {
              border-color: transparent;

              &.is-active {
                border: 1px solid #dcdfe6;
                border-top: 0;
                background-color: white;
                border-radius: 0 0 4px 4px;
              }
            }
          }
        }
      }
    }

    .el-main {
      grid-template-columns: 1fr;
      grid-gap: 25px;
      align-items: center;
      background-color: white;
      position: relative;
      top: -40px;
      padding: 30px 25px 0;

      /** para poder usar vue-autosuggest */
      overflow: inherit;
    }

    @media screen and (max-width: 480px) {
      .el-main {
        display: block;
        top: 0;
      }

      .wapper-search-middle {
        display: none;
      }
    }
  }

  .before-input i,
  .el-input__inner,
  #autosuggest__input {
    height: 45px;
    line-height: 45px;
  }

  .search-wrapper-submit {
    width: 320px;
    margin: 20px auto -18px auto;
    padding: 5px;
    border-radius: 20px;
    background-color: white;
  }

  .container-switch {
    display: flex;
    justify-content: center;

    .el-button {
      flex: 1 0 50%;

      &.button--find-my-tour:focus,
      &.button--find-my-tour:hover {
        background-color: inherit;
        color: rgb(48, 134, 66);
      }
    }
  }

  .wrapper-search-bottom {
    flex-direction: column;
    margin-top: 20px;
  }

  .wapper-search-left {
    margin-top: 0;
    min-height: 45px;

    .el-tabs__header {
      background-color: white;
      border-bottom: 0;
      position: relative;

      .el-tabs__nav {
        border-radius: 0;
        margin: 0;
      }

      .el-tabs__item {
        padding: 0.9375rem 1.25rem;
        height: auto;
        line-height: 1.8;

        @media (max-width: 575.98px) {
          max-width: 6rem;
        }

        i {
          font-size: 1.8em;
        }

        span {
          display: block;
        }

        &:not(:first-child) {
          padding: 0;

          a {
            white-space: pre-wrap;
            display: block;
            padding: 0.9375rem 1.25rem;
          }

          @media (max-width: 575.98px) {
            a {
              padding-bottom: 0 !important;
            }
          }
        }
      }
    }

    .el-tabs__content {
      padding: 0;
      display: none;
    }

    @media screen and (max-width: 480px) {
      .el-tabs__header {
        .el-tabs__item {
          padding: 0;

          i {
            font-size: 2.2em;
            width: 35px;
          }

          span {
            font-size: 11px;
            line-height: 1.6;
            font-weight: normal;
          }

          a {
            display: block;
            padding: 15px 15px 10px 15px !important;
          }
        }
      }
    }
  }
}
</style>
